@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");

@media (max-width: 850px) {
  /* --------------------------
:: Base
-------------------------- */
  * {
    margin: 0;
    padding: 0;
    font-family: "Roboto";
    max-width: 100vw;
    overflow: visible;
  }

  body {
    font-family: "Roboto";
    font-weight: 400;
    background-color: #fff;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #000;
    line-height: 1.2;
  }

  .section_padding_50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .section_padding_50_20 {
    padding-top: 50px;
    padding-bottom: 20px;
  }

  .section_padding_50_0 {
    padding-top: 50px;
    padding-bottom: 0;
  }

  .section_padding_50_80 {
    padding-top: 50px;
    padding-bottom: 80px;
  }

  .section_padding_0_50 {
    padding-top: 0;
    padding-bottom: 50px;
  }

  .bg-gray {
    background-color: #f5f5f5;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
  }

  #scrollUp {
    bottom: 0;
    line-height: 50px;
    font-size: 24px;
    color: #fff;
    text-align: center;
    right: 30px;
    height: 50px;
    width: 50px;
    background-color: #1d7af5;
  }

  a,
  a:hover,
  a:focus,
  a:active {
    text-decoration: none;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
  }

  li {
    list-style: none;
  }

  p {
    color: #726a84;
    font-size: 16px;
    font-weight: 300;
    margin-top: 0;
  }

  .section-heading > h2 {
    color: #000000;
    font-size: 36px;
    line-height: 50px;
    margin: 0;
    font-weight: 500;
    padding-bottom: 30px;
  }

  /* --------------------------
:: Header Area
-------------------------- */

  .header_area {
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 99;
    top: 0;
    padding: 0 4%;
  }

  /* --------------------------
:: Welcome Area
-------------------------- */
  .containerwelcome {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin: 0%;
  }
  .containerlogoStore {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0%;
    width: 100%;
    align-self: center;
    display: none;
  }
  .first.column.welcome {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 2;
    margin: 0% 2%;
  }
  .first.column.title {
    font-family: Roboto;
    font-size: 30px;
    line-height: 1.25;
    color: #30735f;
  }

  .containerlogoStore.responsive {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: unset;
    margin: 5% 0%;
  }
  .first.column.subtitle {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 500;
    font-style: italic;
    line-height: 1.62;
    color: #30735f;
    margin: 8% 0%;
  }
  .first.column.label {
    font-family: Roboto;
    font-size: 15px;
    line-height: 2.14;
    color: #30735f;
    display: none;
  }
  .first.column.label.responsive {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 600;
    line-height: 2.14;
    color: #30735f;
    display: unset;
    align-self: center;
  }
  .second.column.welcome {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-self: center;
    margin: 1% 0%;
  }

  /* --------------------------
:: Area video
-------------------------- */

  #videoplay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 7%;
  }
  .videot {
    object-fit: cover;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    z-index: 999;
    visibility: visible;
    transition: all 0.15s linear;
  }
  .videot.fullscreen {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  /* --------------------------
:: Video Area
-------------------------- */

  .video-area {
    position: relative;
    z-index: 1;
    background-position: center center;
    background-size: auto;
    height: 700px;
    width: 1240px;
    margin-left: -57px;
  }

  .video-area .video-play-btn div {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 3;
  }

  .video-area .video-play-btn img:hover {
    -webkit-animation: pulse 1s;
    animation: pulse 1s;
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }

  .video-container iframe,
  .video-container object,
  .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .video-lightbox {
    display: none;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 1);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }

  .close-video {
    background-image: url(assets/close.png) !important;
    background-size: 15px 15px;
    background-repeat: no-repeat;
    position: absolute;
    top: 30px;
    left: 30px;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
  }

  .vimeo {
    width: 1140px;
    height: 648px;
  }

  .responsive-video {
    margin-bottom: 0;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    width: 100%;
    overflow: hidden;
  }

  /* --------------------------
:: Billetera Area
-------------------------- */

  .billetera-cel {
    width: 100%;
  }

  .billetera-cel img {
    margin-top: 7.5%;
    margin-left: 9%;
  }

  .billetera-text {
    margin-top: 14%;
    margin-left: 13%;
    margin-right: 10%;
  }

  .billetera-text p {
    font-size: 18px;
    color: #4a4a4a;
    line-height: 28px;
    margin-bottom: 2rem;
  }

  .billetera-text ul {
    width: 97%;
  }

  .billetera-text li {
    font-size: 16px;
    color: #4a4a4a;
    line-height: 23px;
    font-weight: 500;
    padding-left: 6px;
    padding-top: 17px;
    padding-bottom: 17px;
    border-bottom: 1px solid #e6e6e6;
  }
  .billetera-text li:nth-child(3),
  .billetera-text li:nth-child(4) {
    font-weight: 300;
  }

  .billetera-text li:first-of-type {
    border-top: 1px solid #e6e6e6;
  }

  .billetera-text li i {
    font-size: 12px;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3db39e;
    border: 2px solid #3db39e;
    float: right;
  }

  /* --------------------------
:: Muchos mas Area
-------------------------- */

  .slick-dotted.slick-slider {
    margin-bottom: 0;
  }

  .muchosmas-col li {
    outline: none;
  }

  .muchosmas-col li i {
    font-size: 30px;
    margin-top: 7px;
    margin-bottom: 7px;
    width: 68px;
    height: 68px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #9b9b9b;
    -webkit-transition-duration: 800ms;
    -o-transition-duration: 800ms;
    transition-duration: 800ms;
    border: 1.2px solid rgba(207, 216, 220, 0.2);
    box-shadow: 0 5px 10px 0 rgba(172, 181, 185, 0.4);
  }

  .muchosmas-col li.slenable i:hover,
  .muchosmas-col li.slick-current i {
    font-size: 33px;
    border-radius: 50%;
    color: #fff;
    background-color: #1873f3;
    border: 1.2px solid rgba(207, 216, 220, 0.2);
    box-shadow: 0 5px 10px 0 rgba(172, 181, 185, 0.4);
  }

  .muchosmas-col li.sldisable i {
    color: #cdcdcd;
    background-color: #f7f7f7;
  }
  .muchosmas-col li.sldisable i svg {
    opacity: 0.4;
  }

  /* --------------------------
:: Muchos M�s Item Area
-------------------------- */

  .muchosmasitem-cel {
    width: 100%;
  }

  .muchosmasitem-cel img {
    margin-top: 4%;
    margin-left: 6%;
    padding-right: 9%;
  }

  .muchosmasitem-text {
    margin-left: 16.5%;
    margin-top: 15.5%;
    margin-right: 10%;
  }

  .muchosmasitem-text img {
    margin-bottom: 8%;
  }

  .muchosmasitem-text p {
    font-size: 18px;
    color: #4a4a4a;
    line-height: 28px;
    margin-bottom: 6%;
  }

  /* --------------------------
:: Customers Area
-------------------------- */

  .customer {
    border: 1px solid #eff2f6;
    padding: 40px;
    border-radius: 40px 40px 40px 0px;
    -webkit-transition-duration: 800ms;
    -o-transition-duration: 800ms;
    transition-duration: 800ms;
    margin-bottom: 30px;
    height: 150px;
    display: inline-flex;
    align-items: center;
  }

  .customer:hover {
    -webkit-box-shadow: 0 10px 90px rgba(0, 0, 0, 0.08);
    box-shadow: 0 10px 90px rgba(0, 0, 0, 0.08);
  }

  /* --------------------------
:: Contact Us Area
-------------------------- */

  .contact-area {
    background: #f5f5f5;
  }

  .contact-row {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contact-box {
    width: 79%;
    border-radius: 6px;
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
  }

  .contact-text > h2 {
    color: #000000;
    font-size: 36px;
    font-weight: 500;
    line-height: 50px;
    text-align: center;
    margin: 0;
    padding-top: 3.5%;
    padding-bottom: 1.5%;
  }

  .contact-text > p {
    color: #4a4a4a;
    font-size: 21px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 3%;
  }

  .contact-form {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 6.5%;
  }

  .contact-form form {
    width: 79%;
  }

  .contact-soy {
    display: -webkit-inline-box;
    margin-bottom: 4%;
    text-align: center;
  }

  .contact-soy input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  .contact-soy [type="radio"] + label {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    border-radius: 6px;
    border: 1px solid #979797;
    min-width: 91%;
    height: 105px;
    padding-top: 69px;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 21%;
  }

  .contact-soy [for="soyRadioConsorcio"] {
    background-image: url(assets/consorcio.svg);
  }

  .contact-soy [for="soyRadioProveedor"] {
    background-image: url(assets/proveedor.svg);
  }

  .contact-soy [for="soyRadioHabitante"] {
    background-image: url(assets/habitante.svg);
  }

  .contact-soy [type="radio"]:checked + label {
    border: 2px solid #1873f3;
    border-radius: 6px;
    box-shadow: 0 6px 12px 0 rgba(172, 181, 185, 0.4);
  }

  .contact-input-area {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contact-fields {
    width: 70%;
  }

  .form-group {
    margin-bottom: 2%;
  }

  .form-group label {
    font-size: 13px;
    color: #4a4a4a;
    margin-bottom: 0;
  }

  .form-group input {
    font-size: 18px;
    color: #000000;
    border: none;
    border-radius: unset;
    border-bottom: 1.5px solid #979797;
    padding: 0;
    height: 36px;
  }

  .form-control::-webkit-input-placeholder {
    color: #9b9b9b;
    /*opacity: 1;*/
  }

  .form-control:-ms-input-placeholder {
    color: #9b9b9b;
    /*opacity: 1;*/
  }

  .form-control::-ms-input-placeholder {
    color: #9b9b9b;
    /*opacity: 1;*/
  }

  .form-control::placeholder {
    color: #9b9b9b;
    /*opacity: 1;*/
  }

  .btn.submit-btn {
    margin-top: 6.5%;
    background: #1873f3;
    font-size: 20px;
    height: 50px;
    min-width: 62%;
    color: #ffffff;
    border-radius: 6px;
  }

  .btn.submit-btn:hover {
    background: #ffffff;
    color: #1873f3;
    border: 1px solid #1873f3;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
  }

  /* --------------------------
:: Footer Area
-------------------------- */

  .footer-social-area {
    margin-bottom: 1.5%;
    width: 100% !important;
    display: flex;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
  }

  .footer-social-area div.row {
    width: 77%;
  }

  .footer-social-area img {
    width: 150px;
    height: 28px;
    vertical-align: -webkit-baseline-middle;
  }

  .footer-logo {
    text-align: left !important;
  }

  .footer-icons {
    text-align: right !important;
  }

  .footer-social-icon > a > i {
    font-size: 22px;
    margin-left: 4%;
    margin-right: 4%;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #1873f3;
    border: 1px solid #fff;
  }

  .footer-social-icon > a > i:hover {
    color: #1873f3;
    background-color: #fff;
    border: 1px solid #1873f3;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
  }

  .footer-social-icon > a .active {
    background: #fb397d;
  }
  .slenable .far:hover {
    cursor: pointer;
  }
  .slenable .far:hover svg path,
  .slick-current .far svg path {
    fill: #fff;
  }

  .slideUp {
    animation-name: slideUp;
    -webkit-animation-name: slideUp;

    animation-duration: 1s;
    -webkit-animation-duration: 1s;

    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;

    visibility: visible !important;
  }

  @keyframes slideUp {
    0% {
      transform: translateY(100%);
    }
    50% {
      transform: translateY(-8%);
    }
    65% {
      transform: translateY(4%);
    }
    80% {
      transform: translateY(-4%);
    }
    95% {
      transform: translateY(2%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @-webkit-keyframes slideUp {
    0% {
      -webkit-transform: translateY(100%);
    }
    50% {
      -webkit-transform: translateY(-8%);
    }
    65% {
      -webkit-transform: translateY(4%);
    }
    80% {
      -webkit-transform: translateY(-4%);
    }
    95% {
      -webkit-transform: translateY(2%);
    }
    100% {
      -webkit-transform: translateY(0%);
    }
  }
  .floating {
    animation-name: floating;
    -webkit-animation-name: floating;

    animation-duration: 0.7s;
    -webkit-animation-duration: 0.7s;

    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
  }

  @keyframes floating {
    0% {
      transform: translateY(0%);
    }
    50% {
      transform: translateY(15%);
    }
    70% {
      -webkit-transform: translateY(35%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0%);
    }
    50% {
      -webkit-transform: translateY(15%);
    }
    70% {
      -webkit-transform: translateY(35%);
    }
    100% {
      -webkit-transform: translateY(0%);
    }
  }

  .slideLeft {
    animation-name: slideLeft;
    -webkit-animation-name: slideLeft;

    animation-duration: 2.5s;
    -webkit-animation-duration: 2.5s;

    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;

    visibility: visible !important;
  }

  @keyframes slideLeft {
    0% {
      transform: translateX(150%);
    }
    50% {
      transform: translateX(-8%);
    }
    65% {
      transform: translateX(4%);
    }
    80% {
      transform: translateX(-4%);
    }
    95% {
      transform: translateX(2%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @-webkit-keyframes slideLeft {
    0% {
      -webkit-transform: translateX(150%);
    }
    50% {
      -webkit-transform: translateX(-8%);
    }
    65% {
      -webkit-transform: translateX(4%);
    }
    80% {
      -webkit-transform: translateX(-4%);
    }
    95% {
      -webkit-transform: translateX(2%);
    }
    100% {
      -webkit-transform: translateX(0%);
    }
  }
}
