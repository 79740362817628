#carousel {
  width: 100%;
  height: 555px;
  position: relative;
  transition: all ease-in-out 0.5s;
  }
  #carousel div {
    position: absolute;
    transition: transform 0.5s,scale 0.2s, left 0.5s, opacity 0.5s, z-index 0s;
    opacity: 1;
  }
  #carousel div img {
    transition: width 0.5s;
  }
  #carousel div.hideLeft {
    left: 0%;
    opacity: 0;
    top: 12%;
  }
  #carousel div.hideLeft img {
    width: 100px !important;
    height: 150px;
    transition: ease-in-out 0.5s;
  }
  #carousel div.hideRight {
    left: 100%;
    opacity: 0;
    top: 12%;
  }
  #carousel div.hideRight img {
    width: 100px !important;
    height: 150px;
    transition: ease-in-out 0.5s;
  }
  #carousel div.prevLeftSecond {
    z-index: 4;
    left: 15%;
    top: 24%;
    opacity: 0.7;
    top: 20%;
    opacity: 0.7;
  }
  #carousel div.prevLeftSecond img {
    width: 200px !important;
    height: 300px;
  }
  #carousel div.prev {
    z-index: 5;
    left: 20%;
    top: 23%;
    height: 300px;
    transform: translateX(10%);
    transition: ease-in-out 0.5s;
    top: 10%;
    height: 480px;
    transition: ease-in-out 0.5s;
  }
  #carousel div.prev img {
    width: 220px !important;
    height: 480px;
  }
  #carousel div.selected {
    z-index: 10;
    left: 33%;
    top: 3vh;
    height: 100%;
    width: 260px;
    overflow: hidden;
    border-radius: 5%;
    transition: ease-in-out 0.5s;
}
  #carousel div.next {
    z-index: 5;
    top: 10%;
    left: 38%;
    height: 480px;
    transform: translateX(50%);
    transition: all ease-in-out 0.5s;
  }
  #carousel div.next img {
    width: 220px !important;
    height: 480px;
  }
  
  #carousel div.nextRight {
    z-index: 4;
    left: 75%;
    top: 10%;
    height: 300px;
    opacity: 0.7;
  }
  #carousel div.nextRight img {
    width: 100px !important;
    height: 300px;
  }

  #carousel div.nextRightSecond {
    z-index: 4;
    left: 60%;
    top: 20%;
    opacity: 0.7;
  }
  #carousel div.nextRightSecond img {
    width: 200px !important;
    height: 300px;
  }
  

  .buttons {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10px;
  }