@media (max-width: 850px) {
  #carousel {
    width: 100% !important;
    margin-top: 0px !important;
    position: relative !important;
  }
  #carousel div {
    position: absolute !important;
    transition: transform 1s, left 1s, opacity 1s, z-index 0s !important;
    opacity: 1 !important;
  }
  #carousel div img {
    width: 50px;
    transition: width 1s !important;
  }
  #carousel div.hideLeft {
    z-index: 9 !important;
    width: 40px !important;
    left: 15% !important;
    top: 6vh !important;
    height: 70px !important;
  }
  #carousel div.hideLeft img {
    z-index: 9 !important;
    width: 40px !important;
    left: 15% !important;
    top: 6vh !important;
    height: 70px !important;
    transition: ease-in-out 0.5s !important;
    opacity: 0 !important;
  }
  #carousel div.hideRight {
    z-index: 8 !important;
    width: 40px !important;
    left: 90% !important;
    top: 6vh !important;
    height: 70px !important;
  }
  #carousel div.hideRight img {
    z-index: 8 !important;
    width: 40px !important;
    left: 90% !important;
    top: 6vh !important;
    height: 70px !important;
    transition: ease-in-out 0.5s !important;
    opacity: 0 !important;
  }
  #carousel div.prevLeftSecond {
    z-index: 9 !important;
    width: 40px !important;
    left: 15% !important;
    top: 10vh !important;
    height: 70px !important;
  }
  #carousel div.prevLeftSecond img {
    width: 50px !important;
  }
  #carousel div.prev {
    z-index: 10 !important;
    width: 70px !important;
    left: 25% !important;
    top: 6vh !important;
    height: 150px !important;
  }
  #carousel div.prev img {
    width: 100px !important;
  }
  #carousel div.selected {
    z-index: 10 !important;
    width: 130px !important;
    left: 34% !important;
    top: 2vh !important;
    height: 273px !important;
  }
  #carousel div.selected img {
    width: 200px !important;
  }
  #carousel div.next {
    z-index: 9 !important;
    width: 70px !important;
    left: 50% !important;
    top: 6vh !important;
    height: 150px !important;
  }
  #carousel div.next img {
    width: 100px !important;
  }
  #carousel div.nextRight {
    z-index: 8 !important;
    width: 30px !important;
    left: 85% !important;
    top: 10vh !important;
    height: 70px !important;
  }
  #carousel div.nextRight img {
    width: 50px !important;
  }
  #carousel div.nextRightSecond {
    z-index: 8 !important;
    width: 40px !important;
    left: 75% !important;
    top: 8vh !important;
    height: 70px !important;
  }
  #carousel div.nextRightSecond img {
    width: 50px !important;
  }
}
